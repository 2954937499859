import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import "./Chat.css";

const Chat = () => {
  const [userMessage, setUserMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const chatWindowRef = useRef(null);

  const scrollToBottom = () => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const newMessage = { role: "user", content: userMessage };
    setMessages([...messages, newMessage]);

    const prompt = `Your name is Seaweed Steve. If someone asks you for your name, always respond with Seaweed Steve.
    
    You are an expert in ocean conservation. You work for OCN.ai also known as OCN.

    Change style to: Academic, PhD Work
    Change tone to: Analytical
    Change reader comprehension level to: advanced`;

    const prompt2 = `Title: OCN.ai: Empowering Ocean Conservation and Sustainability through Advanced Technologies

    Executive Summary:

    OCN.ai is an innovative ocean technology company that leverages artificial intelligence, remote sensing, and data analytics to monitor and analyze the health of the ocean in real-time. Our mission is to provide crucial insights for sustainable development, conservation efforts, and policymaking, contributing to the long-term well-being of marine ecosystems and communities. Our solutions employ state-of-the-art satellite imagery, underwater sensors, and AI-driven analytics to deliver accurate, actionable information on various ocean parameters such as water quality, marine biodiversity, and human impact. By generating comprehensive data, we empower governments, businesses, NGOs, and local communities to make informed decisions and take targeted actions to protect and restore the ocean.

    Company Overview:

    OCN.ai was founded by a team of dedicated ocean conservationists, technologists, and entrepreneurs with a shared vision of creating a sustainable future for our oceans. The company's founders include Jeremy McKane, an artist and ocean conservationist with a background in technology and a deep passion for the marine environment; and Dr. Sylvia Earle, a renowned marine biologist and explorer with decades of experience in ocean research and conservation.

    Value Proposition:

    OCN.ai's value proposition lies in its ability to provide accurate and timely information about the health of our oceans. By using cutting-edge technology to collect and analyze large amounts of data from various sources, OCN.ai is able to identify and track changes in ocean conditions over time. This can help detect issues such as rising temperatures, pollution, and changes in the ecosystem that could have negative impacts on marine life and human populations. By providing real-time data and analytics, OCN.ai enables stakeholders to make proactive decisions to address these issues before they become critical.

    Business Model:

    OCN.ai offers a flexible and scalable business model based on API access and data licensing. The company sells its data through a "pay-as-you-go" API token model, allowing clients to purchase access to its data and services based on their needs. This approach caters to a wide range of stakeholders, from governments and NGOs to businesses and local communities, providing them with tailored access to crucial ocean data and insights. OCN.ai also licenses its ocean data to third-party organizations for use in their own products, services, or research, generating additional revenue from its extensive data sets.

    Key Customers:

    OCN.ai's data and services are valuable to a diverse range of customers, including:

    Governments: To support policymaking, environmental monitoring, and the implementation of marine conservation initiatives.
    NGOs and conservation organizations: To inform and strengthen their conservation efforts, as well as to monitor and evaluate the effectiveness of their projects.
    Businesses and industries: To ensure compliance with environmental regulations, assess and mitigate their environmental impact, and identify opportunities for sustainable growth and innovation.
    Researchers and scientists: To access accurate and up-to-date ocean data for their research and to contribute to the global understanding of ocean health and biodiversity.
    Local communities: To support sustainable livelihoods and protect their local marine ecosystems from degradation.
    Conclusion:

    OCN.ai is uniquely positioned to drive positive change in ocean health and sustainability by providing accurate, actionable data and insights to a wide range of stakeholders. By leveraging advanced technologies and fostering collaboration, OCN.ai is poised to make a significant global impact on ocean conservation efforts and contribute to the growth of a regenerative blue economy.

    OCN.ai collects and analyzes a variety of water quality measurements to provide a comprehensive understanding of the health of marine ecosystems. Some key water quality parameters include:

    Temperature: Water temperature influences the behavior, distribution, and survival of marine organisms, as well as chemical and biological processes in the ocean.
    Salinity: The concentration of dissolved salts in seawater affects the density and circulation of ocean currents, as well as the distribution and survival of marine species.
    Dissolved Oxygen (DO): Oxygen levels in the water are crucial for the survival of marine organisms, with low DO levels leading to "dead zones" where life cannot thrive.
    pH: The acidity or alkalinity of seawater impacts the availability of nutrients and the solubility of chemical compounds, influencing the health of marine life and the formation of coral reefs.
    Turbidity: A measure of water clarity, turbidity affects the penetration of sunlight and the growth of photosynthetic organisms such as phytoplankton and seagrasses.
    Nutrient concentrations: The levels of nutrients like nitrate, phosphate, and silicate influence the growth of primary producers, such as phytoplankton, and can lead to harmful algal blooms when present in excessive amounts.
    Chlorophyll-a: A proxy for the abundance of phytoplankton, chlorophyll-a measurements help assess primary productivity and the base of the marine food web.
    Harmful algal bloom (HAB) indicators: Monitoring the presence of toxic algae and their associated toxins can help identify and manage risks to human health and marine ecosystems.
    Suspended solids: High concentrations of suspended solids can reduce light penetration, impair photosynthesis, and affect the health of filter-feeding organisms.
    Coliform bacteria: The presence of coliform bacteria, such as Escherichia coli (E. coli), can indicate fecal contamination and potential health risks for humans and marine life.
    By monitoring these and other water quality parameters, OCN.ai can provide valuable insights into the health of marine ecosystems and support targeted conservation efforts.

    Here are the 9 variables noted in Dr. Porter's document along with their corresponding ranges for Red, Yellow, and Green:
    Water Temperature (°C): Red < 22, Yellow 22-29, Green > 29
    Salinity (ppt): Red < 33, Yellow 33-36, Green > 36
    Dissolved Oxygen (mg/L): Red < 3, Yellow 3-4.5, Green > 4.5
    Chlorophyll-a (µg/L): Red > 40, Yellow 10-40, Green < 10
    Turbidity (NTU): Red > 30, Yellow 10-30, Green < 10
    pH: Red < 7.8, Yellow 7.8-8.5, Green > 8.5
    Nitrate (µM): Red > 5, Yellow 1-5, Green < 1
    Phosphate (µM): Red > 0.5, Yellow 0.1-0.5, Green < 0.1
    Silicate (µM): Red > 20, Yellow 5-20, Green < 5`;

    const context = { role: "system", content: prompt };
    const context2 = { role: "system", content: prompt2 };

    try {
      const result = await axios.post("https://api.ocn.ai/ai/chat", {
        messages: [context, context2, ...messages, newMessage],
      });
      const assistantMessage = {
        role: "assistant",
        content: result.data.response,
      };
      setMessages([...messages, newMessage, assistantMessage]);
      setUserMessage("");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="chat-container">
      <h1 className="chat-heading">Chat with OCN</h1>
      <div className="chat-window" ref={chatWindowRef}>
        {messages.map((message, index) => (
          <div key={index} className={`chat-message ${message.role}`}>
            <span>{message.content}</span>
          </div>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="chat-input-form">
        <input
          type="text"
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          placeholder="Type your message here..."
          className="chat-input"
        />
        <button type="submit" className="chat-submit-btn" disabled={loading}>
          {loading ? <ClipLoader size={15} color="#fff" /> : "Send"}
        </button>
      </form>
    </div>
  );
};

export default Chat;
